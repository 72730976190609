import './style.css';
import React, { useState } from 'react'

function Calculator (){

    const [expr, setExpr] = useState("");

    const handleInput = (e) => {
        if(e.target.value == "="){    
            setExpr(eval(expr));
        }else if(e.target.value == "all-clear"){
            setExpr("");
        }else if(e.target.value == "last-clear"){
            setExpr(expr.slice(0, -1));
            console.log(expr);
        }else{
            setExpr(expr + e.target.value);
            console.log(expr);
        }
    }

    return (
        <div>
        
            <div class="container my-4">  

            <h1 className="text-center p-4">Calculator</h1>
            <hr class="mb-5"/>  


            <div class="calculator card">

                <input type="text" className="calculator-screen z-depth-1" value={expr} disabled />

                <div className="calculator-keys">

                <button type="button" className="operator btn btn-info" value="+" onClick={(e) => handleInput(e)}>+</button>
                <button type="button" className="operator btn btn-info" value="-" onClick={(e) => handleInput(e)}>-</button>
                <button type="button" className="operator btn btn-info" value="*" onClick={(e) => handleInput(e)}>&times;</button>
                <button type="button" className="operator btn btn-info" value="/" onClick={(e) => handleInput(e)}>&divide;</button>

                <button type="button" value="7" className="btn btn-light waves-effect" onClick={(e) => handleInput(e)}>7</button>
                <button type="button" value="8" className="btn btn-light waves-effect" onClick={(e) => handleInput(e)}>8</button>
                <button type="button" value="9" className="btn btn-light waves-effect" onClick={(e) => handleInput(e)}>9</button>


                <button type="button" value="4" className="btn btn-light waves-effect" onClick={(e) => handleInput(e)}>4</button>
                <button type="button" value="5" className="btn btn-light waves-effect" onClick={(e) => handleInput(e)}>5</button>
                <button type="button" value="6" className="btn btn-light waves-effect" onClick={(e) => handleInput(e)}>6</button>


                <button type="button" value="1" className="btn btn-light waves-effect" onClick={(e) => handleInput(e)}>1</button>
                <button type="button" value="2" className="btn btn-light waves-effect" onClick={(e) => handleInput(e)}>2</button>
                <button type="button" value="3" className="btn btn-light waves-effect" onClick={(e) => handleInput(e)}>3</button>


                <button type="button" value="0" className="btn btn-light waves-effect" onClick={(e) => handleInput(e)}>0</button>
                <button type="button" className="decimal function btn btn-secondary" value="." onClick={(e) => handleInput(e)}>.</button>
                <button type="button" className="all-clear function btn btn-danger btn-sm" value="all-clear" onClick={(e) => handleInput(e)}>AC</button>

                <button type="button" className="equal-sign operator btn btn-dark" value="=" onClick={(e) => handleInput(e)}>=</button>
                <button type="button" className="last-clear btn btn-danger btn-sm" value="last-clear" onClick={(e) => handleInput(e)}>X</button>

                </div>
            </div>
            </div>
        </div>
    )
}

export default Calculator;