import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useState} from 'react';
import Calculator from './calculator/Calculator';

function App() {

  return (
    <div>
      <Calculator />
    </div>
  );
}

export default App;
